import { useEffect, useState } from 'react';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import PageWrapper from '@components/pageWrappers';
import CustomerSupport from '@components/CustomerSupport';
import FrontPageHero from '@components/heros/FrontPageHero';
import { useAccount, useShippingCountry, useSubscriptions } from '@lib/hooks';
import { TargetedUpsellProps } from '@components/TargetedUpsell';
import { shippingCountries } from '@lib/utils.delivery';
import {
  AccountProps,
  CampaignLocation,
  DisabledLegacyShippingCountry,
} from '@types';
import { WithCampaignProps, withCampaigns } from '@lib/hoc';
import { isSubscriptionActive } from '@lib/utils.subscription';

const CampaignHero = dynamic(() => import('@components/heros/CampaignHero'));
const TargetedUpsell = dynamic(() => import('@components/TargetedUpsell'));
const SubscriptionList = dynamic(() => import('@components/SubscriptionList'));
const UpsellSlider = dynamic(() =>
  import('@components/TargetedUpsell').then((module) => module.UpsellSlider),
);
const DisabledCountryLander = dynamic(
  () => import('@components/DisabledCountryLander'),
);

const checkAccountError = ({ errors }: AccountProps) => {
  if (errors) {
    if (errors.includes('user')) {
      return 'user';
    }
    if (errors.includes('address')) {
      return 'address';
    }
  }

  return false;
};

const Home = ({ campaigns }: WithCampaignProps) => {
  const { push, query } = useRouter();
  const { country, loading: countryLoading } = useShippingCountry();

  const {
    subscriptions,
    dogs = [],
    loading: subscriptionsLoading,
  } = useSubscriptions();
  const { account, loading: accountLoading } = useAccount();

  const [upsellProps, setUpsellProps] = useState<null | TargetedUpsellProps>(
    null,
  );

  const loading = subscriptionsLoading || accountLoading || countryLoading;

  const shippingCountryDisabled =
    country &&
    Object.values(DisabledLegacyShippingCountry).includes(
      country as unknown as DisabledLegacyShippingCountry,
    );

  const isSubscriber =
    subscriptionsLoading ||
    Object.values(subscriptions || {}).filter(isSubscriptionActive).length > 0;

  useEffect(() => {
    if (!upsellProps) {
      const subscriptionValues = Object.entries(subscriptions || {});
      if (subscriptionValues.length > 0) {
        const [firstDogId, firstSubscription] = subscriptionValues[0];
        const dog = dogs.find((d) => d.dog_id.toString() === firstDogId);

        if (dog && firstSubscription) {
          setUpsellProps({ dog, subscription: firstSubscription });
        }
      }
    }
  }, [subscriptions]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (!shippingCountryDisabled) {
      const accountError = checkAccountError(account);

      if (accountError === 'user') {
        push('/profile/user');
        return;
      }

      if (accountError === 'address') {
        push('/profile/shipping');
        return;
      }

      if (!account.has_subscribed) {
        // customer isn't a subscriber, redirect to profile
        // for some reason this needs explicit locale to not pass it as a query param
        push(`/${query.locale}/profile/`);
        return;
      }
    }
  }, [account, push, loading, query.locale, subscriptions, shippingCountries]);

  if (shippingCountryDisabled) {
    return <DisabledCountryLander country={country} />;
  }

  return (
    <PageWrapper>
      <CampaignHero fallBack={<FrontPageHero />} campaigns={campaigns} />
      <SubscriptionList activeOnly hideIfEmpty={!isSubscriber} align="center" />
      {isSubscriber ? (
        upsellProps ? (
          <TargetedUpsell {...upsellProps} />
        ) : (
          <UpsellSlider loading dogName="" />
        )
      ) : null}
      <CustomerSupport />
    </PageWrapper>
  );
};

export const getServerSideProps: GetServerSideProps = async () => ({
  props: {
    title: 'frontpage',
    metadata: {
      description: 'meta_description.frontpage',
    },
  },
});

export default withCampaigns(Home, {
  location: CampaignLocation.home,
});
