import { useCallback, useEffect } from 'react';

import { CampaignSettings } from '../types';
import { useCampaigns, useIsLoggedIn } from './hooks';
import { clearSessionStorage } from './utils.persist';
import { getRecommendationState } from './utils.recommendation';
import { CampaignWithCoupon } from 'pages/api/campaign';

/*
 * During the recommendation process, unverified users (users whose password is not yet set by themselves) get an hour long token, but the process
 * can take long, eg. starts on mobile phone and continues on same device days later. The hour long token is so that
 */
export const withRecommendationTokenValidator = (Component: any) => {
  const TokenValidator = (props: any) => {
    const { isLoggedIn } = useIsLoggedIn();
    const { token_expires } = getRecommendationState();

    const startOverOnExpire = useCallback(() => {
      /* if user has began filling in the recommendation flow, but left it unfinished, the temporary token can go old. remove all data if that happens.
       * poll only tab visibility, because it's the lightest
       */
      if (
        document.visibilityState === 'visible' &&
        token_expires &&
        !process.env.NEXT_PUBLIC_PREVENT_SESSION_EXPIRATION
      ) {
        if (new Date((token_expires || 0) * 1000) <= new Date()) {
          console.info('Session expired: ', token_expires);

          clearSessionStorage();
          window.location.href = '/recommendation?session-expired';
        }
      }
    }, [token_expires]);
    useEffect(() => {
      if (!isLoggedIn) {
        document.addEventListener('visibilitychange', startOverOnExpire, false);
      }
      return () => {
        document.removeEventListener(
          'visibilitychange',
          startOverOnExpire,
          false,
        );
      };
    }, [token_expires, isLoggedIn, startOverOnExpire]);

    return <Component {...props} />;
  };
  return TokenValidator;
};

export interface WithCampaignProps {
  campaigns: CampaignWithCoupon[];
}

export const withCampaigns = (
  Component: any & WithCampaignProps,
  config: CampaignSettings,
) => {
  const CampaignData = (props: any = {}) => {
    const { campaigns = [] } = useCampaigns(config);
    const castProps = {
      ...props,
      campaigns,
    };
    return <Component {...castProps} />;
  };
  return CampaignData;
};
